import React from "react"
import { injectIntl, Link } from "gatsby-plugin-intl"
import get from "lodash/get"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import styled from "styled-components"
import Slider from "react-slick"
import ReactTooltip from "react-tooltip"
import Card from "../components/card"
import Button, { StyledExternalButton, StyledExternalPrimaryButton, StyledInternalButton } from "../components/button"
import Map from "../components/map"
import Layout from "../components/layout"
import DatePicker from "../components/date-picker"
import { colors } from "../theme"
import { TranslationContext } from "../translate"
import sliderArrowImage from "../images/slider-arrow.png"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import merge from "lodash/merge"
import Reference from "../components/reference"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const HeroVideo = styled('video')`
  display: block;
  width: 100%;
  height: 56vw;
  min-height: 25vw;
  max-height: calc(100vh - 5em);
  object-fit: cover;
  @media (min-width: 769px) {
  }
`

const HeroTitle = styled.h1`
  color: #fff;
  position: absolute;
  top: 50%;
  font-size: 5vw;
  text-align: center;
  max-width: 18em;
  width: calc(100% - 2em);
  left: 50%;
  transform: translate(-50%,-65%);
  font-weight: 400;
  text-shadow: 0 0 0.5em rgba(0,0,0,0.75);
  @media (min-width: 769px) {
    font-size: 3em;
  }
`

const HeroOverlayImage = styled.a`
  position: absolute;
  display: block;
  right: 1em;
  top: calc(55% + 1.5em);
  height: calc(50% - 5em);
  width: auto;
  user-select: none;
  object-fit: contain;
  @media (min-width: 769px) {

  }
  @media (min-width: 1024px) {
  }
  @media (min-width: 1280px) {
  }
  img {
    display: block;
    width: auto;
    height: 100%;
    object-fit: contain;
    margin: 0 auto;
  }
`

const HeroOverlayImage2 = styled(HeroOverlayImage)`
  top: 7em;
  right: 1.2em;
  @media (min-width: 769px) {
    right: 1.7em;
  }
`

const HeroWidget = styled.div`
  position: absolute;
  left: 3.5em;
  top: 50%;
  margin-top: -6em;
  display: none;
  @media (min-width: 1024px) {
    display: none;
  }
`

const Intro = styled('div')`
  background: #333230;
  padding: 6em 2em 4em 2em;
  text-align: center;
  position: relative;
  margin-bottom: 2em;
  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -3em;
    border: 3em solid transparent;
    border-top-color: #333230;
  }
`

const IntroTitle = styled('h2')`
  font-size: 1em;
  margin: 0;
  @media (min-width: 769px) {
    flex-direction: row;
    font-size: 2em;
  }
`

const IntroLine = styled('span')`
  color: #B99874;
  display: block;
  font-weight: 300;
  max-width: 23em;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.4em;
`

const IntroLinePrimary = styled(IntroLine)`
  font-weight: 700;
  font-size: 6.4vw;
  line-height: 1em;
  margin-bottom: 0.2em;
  @media (min-width: 769px) {
    flex-direction: row;
    font-size: 2.1em;
  }
`

const IntroIcons = styled('div')`
  margin: 2.5em auto 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 50em;
  font-size: 0.7em;
  @media (min-width: 769px) {
    font-size: 1em;
  }
`

const IntroIconWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.2em;
  opacity: 1;
  transition: opacity 300ms ease-out;
  font-size: 2.4vw;
  @media (min-width: 769px) {
    opacity: 0.25;
    margin: 0.5em;
    font-size: 1em;
  }
  &:hover {
    opacity: 1;
  }
`

const IntroIconImage = styled('img')`
  max-width: 7em;
  height: 5em;
  margin-bottom: 1em;
  background-image: url(${({src}) => src});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
`

const IntroIconTitle = styled('div')`
  color: rgb(162 161 154);
  text-transform: uppercase;
  font-weight: 500;
`

const IntroContent = styled('div')`
  color: #73726E;
  font-weight: 500;
  font-style: italic;
  line-height: 1.5em;
  padding: 2em 1em;
  max-width: 50em;
  margin: 0 auto;
  @media (min-width: 769px) {
    padding: 2em;
  }
`

const IntroButtons = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  margin-top: 0.5em;
  margin-left: auto;
  margin-right: auto;
  max-width: 20em;
  & > * {
    margin: 0.25em 0;
  }
  @media (min-width: 769px) {
    flex-direction: row;
    max-width: none;
    & > * {
      margin: 0 0.25em;
      & > *:last-child {
        margin-left: 1em;
      }
    }
  }
`

const IntroFooterImages = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
  margin-bottom: -1em;
  flex-wrap: wrap;
`

const IntroFooterImage = styled.img`
  margin: 1em;
  width: 7em;
`

const Section = styled('section')`
  overflow: hidden;
  max-width: 90em;
  margin: 0 auto;
`

const SectionTitle = styled('h2')`
  font-size: 3em;
  text-transform: ${({uppercase}) => uppercase ? 'uppercase' : 'none'};
  font-weight: 800;
  text-align: center;
  padding: 0 0.25em;
  margin: 1em 0 0.5em 0;
  margin-top: max(1em, 6vw);
  line-height: 1em;
  & > strong {
    font-weight: 200;
  }
  @media (min-width: 641px) {
    font-size: 4em;
  }
`

const SectionSubtitle = styled('h3')`
  font-size: 2em;
  font-weight: 300;
  text-align: center;
  color: #AAA9A7;
  margin-top: -0.2em;
`

const SectionContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  @media (min-width: 769px) {
    flex-direction: row;
    justify-content: center;
  }
`

const SectionHeader = styled('div')`
  background-image: url(${({backgroundImage}) => backgroundImage});
  background-position: left center;
  background-size: cover;
  position: relative;
  padding: 0 1em;
  min-height: 15em;
  @media (min-width: 769px) {
    flex: 0 0 50%;
    padding: 0;
  }
`

const SectionHeaderContent = styled('div')`
  background: rgba(255,255,255,0.80);
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  margin: 3em auto;
  min-height: 15em;
  max-width: 20em;
  display: none;
  @media (min-width: 769px) {
    margin: 3em;
    max-width: none;
    display: block;
  }
  @media (min-width: 1024px) {
    min-height: auto;
    margin: 0;
    position: absolute;
    top: 0;
    left: 50%;
    right: 0;
    bottom: 0;
  }
`

const SectionHeaderLinks = styled('div')`
  display: flex;
  flex-direction: row;
  letter-spacing: 0.1em;
  align-items: flex-end;
  flex: 1;
`

const SectionHeaderLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  color: #333230;
  &:hover {
    text-decoration: underline;
  }
`

const SectionHeaderSeparator = styled('span')`
  flex: 1;
  text-align: center;
`

const SectionContent = styled('div')`
  font-weight: 500;
  font-style: italic;
  line-height: 1.35em;
  padding: 0 4em 2em 3em;
  box-sizing: border-box;
  max-width: 55em;
  text-align: center;
  & > p:first-child {
    color: ${({color}) => color};
    font-weight: 700;
  }
  a {
    color: ${({color}) => color};
  }
  @media (min-width: 769px) {
    flex: 0 0 ${({fullWidth}) => fullWidth ? "100%" : "50%"};
    text-align: ${({fullWidth}) => fullWidth ? "center" : "left"};
  }
`

const SectionImageWrapper = styled('div')`
  display: block;
  width: 50%;
  position: relative;
  padding-top: 50%;
`

const SectionImage = styled('img')`
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
`

const ContentBlocks = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: -3em 0 4em 0;
  @media (min-width: 769px) {
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
  }
`

const ContentBlock = styled('div')`
  white-space: pre-wrap;
  text-align: center;
  width: 16em;
  padding: 1em 2em;
  a {
    color: inherit;
  }
  @media (min-width: 769px) {
    &:not(:last-child) {
      border-right: 2px solid #EAEAE9;
    }
  }
`

const ContentBlockTitle = styled('h3')`
  color: #B99874;
  font-weight: 400;
  font-size: 1.3em;
  text-transform: uppercase;
  margin: 0 0 0.5em 0;
`

const ContentBlockContent = styled('div')`
  line-height: 1.75em;
`

const Certificates = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
`

const Certificate = styled('img')`
  width: auto;
  height: 5em;
  object-fit: contain;
  margin: 1em;
`

const MapWrapper = styled('div')`
  position: relative;
`

const DatePickerWrapper = styled.div`
  position: absolute;
  bottom: 2em;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ebe7dd;
  box-shadow: 0 0 0.5em rgba(0,0,0,0.5);
  font-size: 1.1em;
  @media (min-width: 769px) {
    padding: 2em 4em;
  }
`

const DatePickerTitle = styled.h2`
  font-size: 1em;
  margin: 0.5em 0;
  text-align: center;
  @media (min-width: 1024px) {
    text-align: left;
    margin: 0 0 1em 0;
  }
`

const Section2 = styled.div`
  display: flex;
  flex-direction: column;
  ${StyledExternalButton},
  ${StyledInternalButton} {
    display: flex;
    align-self: flex-start;
    color: ${colors.pakuri};
    &:hover {
      background: ${colors.pakuri} !important;
      color: #fff !important;
    }
  }
`

const FormSubmitButton = styled.input`
  border: 1px solid ${colors.pakuri};
  background: transparent;
  appearance: none;
  font-size: 0.9em;
  padding: 0.6em 1.2em;
  font-weight: 400 !important;
  font-style: normal !important;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  transition: all 200ms ease-in-out;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-decoration: none !important;
  display: flex;
  align-self: flex-start;
  color: ${colors.pakuri};
  &:hover {
    background: ${colors.pakuri} !important;
    color: #fff !important;
  }
`

const Section2Content = styled.div`
  max-width: 60em;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 3em;
  padding: 3em 2em 4em 2em;
  box-sizing: border-box;
  @media (min-width: 1024px) {
    flex-direction: row;
    padding: 3em 0 4em 0;
  }
`

const Section2Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const Section2ColumnCentered = styled(Section2Column)`
  text-align: center;
  align-items: center;
`

const Section2Image = styled.img`
  width: 100%;
  margin-bottom: 2em;
  display: block;
`

const Section2Header = styled.h2`
  font-weight: 400;
  font-size: 1.8em;
  line-height: 1.25em;
  margin: 0 0 0.75em 0;
`

const Section2HeaderCentered = styled(Section2Header)`
  text-align: center;
  margin-bottom: -0.5em;
`

const Section2Text = styled.div`
  line-height: 1.5em;
  margin-bottom: 1.5em;
`

const Section2Mid = styled(Section2)`
  background: #f1eae3;
`

const Section2Dark = styled(Section2)`
  background: rgb(51, 50, 48);
  color: #EFEFEE;
  padding-top: 1em;
  ${Section2Header} {
    color: rgb(185, 152, 116);
  }
`

const Section2ContentBox = styled(Section2Content)`
  background: #fff;
  padding: 0;
  margin-top: 4em;
  margin-left: 2em;
  margin-right: 2em;
  width: auto;
  &:last-child {
    margin-bottom: 4em;
  }
  gap: 0;
  ${Section2Header} {
  }
  ${Section2Image} {
    margin-top: -2em;
    margin-bottom: -2em;
  }
  ${Section2Column} {
    padding-top: 2em;
    padding-bottom: 2em;
    justify-content: center;
    & > *:not(img) {
      margin-left: 3rem;
      margin-right: 3rem;
    }
  }

  @media (min-width: 1024px) {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
`

const SliderWrapper = styled.div`
  width: calc(100% + 1em);
  max-width: 100vw;
  overflow: hidden;
  margin-top: -4em;
  margin-right: -1em;
`


const SliderArrowRight = styled.div`
  font-size: inherit !important;
  width: 2em;
  height: 2em;
  right: -3em;
  transform: translateY(-50%);
  background: transparent url(${sliderArrowImage}) no-repeat center center;
  background-size: contain;
  &:before {
    content: none !important;
    display: none !important;
  }
  &:hover {
    background: transparent url(${sliderArrowImage}) no-repeat center center;
    background-size: contain;
    opacity: 0.5;
  }
  @media (max-width: 980px) {
    display: none !important;
  }
`

const SliderArrowLeft = styled(SliderArrowRight)`
  transform: translateY(-50%) scaleX(-1);
  right: auto;
  left: -3em;
`



const SliderImageWrapper = styled('div')`
  display: block;
  width: 100%;
  position: relative;
  padding-top: 60%;
`

const SliderImage = styled.img`
  object-fit: cover;
  width: calc(100% - 1em);
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
`

const ReviewPlaceholder = styled.div`
  position: relative;
  width: 100%;
  height: 240px;
`


const ReviewPlaceholderContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 1em);
  height: 100%;
  text-align: center;
  color: #{colors.pakuri};
  font-style: italic;
  line-height: 1.5em;
  padding: 1em;
  box-sizing: border-box;
  font-family: Gelasio, Georgia, serif;
`

const FormLabel = styled.label`
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-top: 0.5em;
  margin-bottom: 0.25em;
`


const bigButtonStyles = {
  fontSize: "1.3em",
  maxWidth: "16em",
  transform: "translateY(50%)",
  margin: "0 auto",
  position: "relative",
  borderWidth: "3px",
}

const ReserveButton = styled(StyledExternalPrimaryButton)`
  background: ${colors.havu};
  color: #ffffff !important;
  border-color: ${colors.havu};
  font-size: 1.3em;
  align-self: center;
  text-align: center !important;
  display: flex;
  max-width: 16em;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  white-space: nowrap;
  &:hover {
    border-color: #fff;
    color: ${colors.havu} !important;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`


const IndexPage = ({data, location, intl}) => {

  const isFinnish = intl.locale === "fi"
  const sections = get(data, 'allContentfulHomeSection.edges')
  const layoutSettings = get(data, 'allContentfulLayoutSettings.edges[0].node')
  const introLine1 = get(layoutSettings, 'introLine1')
  const introLine2 = get(layoutSettings, 'introLine2')
  const introContent = get(layoutSettings, 'introContent.introContent')
  const videoUrlMp4 = get(layoutSettings, 'headerVideo.localFile.publicURL')
  const videoUrlWebm = get(layoutSettings, 'headerVideoWebm.localFile.publicURL')
  const introIcons = get(data, 'allContentfulIntroIcon.edges')
  const introButtons = get(layoutSettings, 'introButtons') || []
  const introFooterImages = get(layoutSettings, 'introFooterImages') || []
  const homeContent = get(layoutSettings, 'homeContent') || []

  const breakpoints = useBreakpoint()
  const isDesktop = !breakpoints.sm

  const heroContent = <TranslationContext.Consumer>
    {(t) => {
      return <>
        <HeroVideo playsInline autoPlay muted loop>
          <source src={videoUrlWebm} type="video/webm" />
          <source src={videoUrlMp4} type="video/mp4" />
        </HeroVideo>
        <HeroTitle>{t(introLine1)}</HeroTitle>
        {/*<DatePickerWrapper>
          <DatePickerTitle>Varaa majoitus</DatePickerTitle>
          <DatePicker />
          </DatePickerWrapper>*/}

        <ReserveButton href="#" className="distributor">{isFinnish ? "Varaa majoitus" : t("Book accommodation")}</ReserveButton>
      </>
  }}</TranslationContext.Consumer>

  return (
    <Layout heroContent={heroContent} data={data} location={location}>

    <TranslationContext.Consumer>
      {(t) => {
      return <>

        {homeContent &&
          homeContent.map((section, i) => {
            return <Reference key={section.id} {...section} />
        })}

        <MapWrapper>
          <Map />
        </MapWrapper>
        <ReactTooltip place="top" effect="solid" type="dark" />
        </>
      }}
      </TranslationContext.Consumer>
    </Layout>
  )
}

export default injectIntl(IndexPage);
export const pageQuery = graphql`
  query IndexQuery($locale: String) {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulContentBlock(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          title
          content {
            raw
          }
        }
      }
    }
    allContentfulHomeSection(filter: { node_locale: { eq: $locale } } sort: { fields: [order], order: ASC }) {
      edges {
        node {
          identifier
          title
          subtitle
          content {
            raw
            references {
              ... on ContentfulContentBlock {
                title
                content {
                  raw
                }
              }
            }
          }
          order
          primaryNavigation
          primaryImage {
            localFile {
              childImageSharp {
                fixed(width: 1000) {
                  src
                }
              }
            }
          }
          images {
            localFile {
              childImageSharp {
                fixed(width: 1000) {
                  src
                }
              }
            }
          }
          color
          certificates {
            title
            localFile {
              childImageSharp {
                fixed(width: 400) {
                  src
                }
              }
            }
          }
          cards {
            ...Card
          }
          buttons {
            ...Button
          }
          linkButtons {
            ...Button
          }
        }
      }
    }
    allContentfulIntroIcon(filter: { node_locale: { eq: $locale } } sort: { fields: [order], order: ASC }) {
      edges {
        node {
          title
          image {
            localFile {
              url
            }
          }
        }
      }
    }
    allContentfulLayoutSettings(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          title
          description
          introLine1
          introLine2
          introContent {
            introContent
          }
          introButtons {
            ...Button
          }
          primaryLogo {
            localFile {
              childImageSharp {
                fixed(width: 600) {
                  src
                  srcSet
                }
              }
            }
          }
          secondaryLogo {
            localFile {
              childImageSharp {
                fixed(width: 600) {
                  src
                  srcSet
                }
              }
            }
          }
          tertiaryLogo {
            localFile {
              childImageSharp {
                fixed(width: 600) {
                  src
                  srcSet
                }
              }
            }
          }
          headerVideo {
            localFile {
              publicURL
            }
          }
          headerVideoWebm {
            localFile {
              publicURL
            }
          }
          headerOverlayImage {
            localFile {
              childImageSharp {
                fixed(width: 900) {
                  src
                }
              }
            }
          }
          headerOverlayImageLink
          headerOverlayImage2 {
            localFile {
              childImageSharp {
                fixed(width: 900) {
                  src
                }
              }
            }
          }
          headerOverlayImageLink2
          introFooterImages {
            localFile {
              childImageSharp {
                fixed(width: 500) {
                  src
                }
              }
            }
          }
          cookieConsentText
          cookieConsentButtonText
          sideMenuButtons {
            ...Button
          }
          primaryMenuButtons {
            ...Button
          }
          secondaryMenuButtons {
            ...Button
          }
          footerMenuButtons {
            ...Button
          }
          ogTitle
          ogDescription
          ogImage {
            localFile {
              childImageSharp {
                fixed(width: 900) {
                  src
                }
              }
            }
          }
          serviceNotification
          notificationBannerContent {
            raw
          }
          showTripAdvisorWidget
          homeContent {
            ... on Node {
              __typename
              id
              ...PageSection
            }
          }
        }
      }
    }
    allContentfulContactInformation(filter: {node_locale: {eq: $locale}} sort: { fields: [order], order: ASC }) {
      edges {
        node {
          icon {
            localFile {
              publicURL
            }
          }
          order
          linkUrl
          label
        }
      }
    }
    allContentfulSocialMediaLink(filter: {node_locale: {eq: $locale}} sort: { fields: [order], order: ASC }) {
      edges {
        node {
          title
          url
          icon {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`
